
import Vue from 'vue'

export default Vue.extend({
    name: 'MicroBadge',
    props: {
        text: {
            type: String,
        },
        textStyle: {
            type: String,
            default: 'text-xs',
        },
        showTextInMobile: {
            type: Boolean,
            default: false,
        },
        fullBadge: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        fullBadgeClass(): string {
            return this.fullBadge ? 'full-badge' : ''
        },
        parsedTextStyle(): string {
            if (this.textStyle === 'hidden') {
                return 'hidden'
            } else {
                const showTextInMobile = this.showTextInMobile ? 'block' : 'hidden sm:block'
                return this.textStyle + ' ' + showTextInMobile
            }
        },
    },
})
