
import Vue, { PropOptions } from 'vue'
import { Models } from '@/types/models'

export default Vue.extend({
    name: 'BtnDiscount',
    props: {
        info: Object as PropOptions<{
            inResume: boolean
            inCard: boolean
            isAdmin: boolean
            showCode: boolean
            slug: string
            code: string
            mode: 'offer' | 'offer-code' | 'coupon' | 'no-link-offer' | 'lottery' | 'no-link-coupon'
            openLottery: () => any
            openCoupon: () => any
            openOffer: () => any
            isOnlyToView?: boolean
            discount: Models.Discount
        }>,
    },
    data() {
        return {
            copied: false,
        }
    },
    computed: {
        textSize(): string {
            switch (true) {
                case this.info.code.length > 16:
                    return 'large' + (this.info.inResume ? ' small' : '')
                case this.info.code.length > 12:
                    return 'medium' + (this.info.inResume ? ' small' : '')
                case this.info.code.length > 6:
                    return 'short' + (this.info.inResume ? ' small' : '')
                default:
                    return 'normal' + (this.info.inResume ? ' small' : '')
            }
        },
        buttonText(): string {
            return this.info.mode === 'coupon' || this.info.mode === 'no-link-coupon'
                ? this.$util.lang.components.btnDiscount.see_coupon
                : this.info.mode === 'lottery'
                ? this.$util.lang.components.btnDiscount.participate_lottery
                : this.$util.lang.components.btnDiscount.see_offer
        },
        code(): string {
            return this.info.code || this.$util.lang.components.btnDiscount.no_coupon_required
        },
        showCode(): boolean {
            return (
                (!this.info.isAdmin &&
                    (this.info.mode === 'offer-code' ||
                        ((this.info.mode === 'coupon' || this.info.mode === 'no-link-coupon') &&
                            this.info.showCode))) ||
                (this.info.isAdmin && this.info.showCode)
            )
        },
        showButton(): boolean {
            return /^(offer-code|offer|lottery|coupon)$/.test(this.info.mode)
        },
        showNLink(): boolean {
            return (
                (this.info.mode === 'no-link-coupon' && !this.info.showCode) ||
                this.info.mode === 'no-link-offer'
            )
        },
        buttonHandler(): () => any {
            return this.info.isOnlyToView
                ? () => null
                : this.info.mode === 'coupon'
                ? () => {
                      this.$copyText(this.code)
                      this.info.openCoupon()
                  }
                : this.info.mode === 'offer' || this.info.mode === 'offer-code'
                ? this.info.openOffer
                : this.info.mode === 'lottery'
                ? this.info.openLottery
                : () => null
        },
        noLinkOfferUrl(): string {
            return this.info.isOnlyToView
                ? '#'
                : this.info.mode === 'no-link-offer'
                ? `/${this.$util.lang.routes.offers}/${this.info.slug}`
                : this.info.mode === 'no-link-coupon'
                ? `/${this.$util.lang.routes.coupons}/${this.info.slug}`
                : ''
        },
        containerClass(): { [x: string]: boolean } {
            return {
                'small-button': this.info.inResume,
                'is-card': this.info.inCard,
                'is-dual':
                    !this.info.isAdmin && (this.info.mode === 'offer-code' || this.info.mode === 'coupon'),
                'is-coupon': this.info.mode === 'coupon' || this.info.mode === 'no-link-coupon',
            }
        },
        discountCodeClass(): { [x: string]: boolean } {
            return {
                'is-secondary-outline': this.info.mode === 'offer-code',
                'is-primary-outline': /^(coupon|no-link-coupon)$/.test(this.info.mode),
            }
        },
        discountBtnClass(): { [x: string]: boolean } {
            return {
                'is-admin-coupon': this.info.mode === 'coupon' && this.info.isAdmin,
                'is-primary': /^(coupon|lottery)$/.test(this.info.mode),
                'is-secondary': /^(offer|offer-code)$/.test(this.info.mode),
                'is-dual':
                    !this.info.isAdmin && (this.info.mode === 'offer-code' || this.info.mode === 'coupon'),
                'is-coupon': this.info.mode === 'coupon',
                'is-offer': this.info.mode === 'offer',
                'is-lottery': this.info.mode === 'lottery',
            }
        },
        discountLinkClass(): { [x: string]: boolean } {
            return {
                'is-secondary-outline': this.info.mode === 'no-link-offer',
                'is-primary-outline is-coupon': this.info.mode === 'no-link-coupon',
            }
        },
        discountCodeIcon(): string {
            return this.$assets[
                this.info.mode === 'coupon' || this.info.mode === 'no-link-coupon' ? 'primary' : 'secondary'
            ].copyCode
        },
    },
    methods: {
        copyText() {
            if (this.code === this.$util.lang.components.btnDiscount.no_coupon_required) return
            this.$copyText(this.code)
            this.copied = true

            setTimeout(() => {
                this.copied = false
            }, 2000)
        },
    },
})
