import LANG from '../lang/es'

export const getDictionary = {
    offer: {
        variant: 'offer',
        path: `/${LANG.routes.offers}`,
        wordPlural: LANG.lib.variant_dict.offer.plural,
        wordSingular: LANG.lib.variant_dict.offer.singular,
        wordSingularOne: LANG.lib.variant_dict.offer.singular_one,
        wordPlain: LANG.lib.variant_dict.offer.plain,
    },
    coupon: {
        variant: 'coupon',
        path: `/${LANG.routes.coupons}`,
        wordPlural: LANG.lib.variant_dict.coupon.plural,
        wordSingular: LANG.lib.variant_dict.coupon.singular,
        wordSingularOne: LANG.lib.variant_dict.coupon.singular_one,
        wordPlain: LANG.lib.variant_dict.coupon.plain,
    },
    lottery: {
        variant: 'lottery',
        path: `/${LANG.routes.lotteries}`,
        wordPlural: LANG.lib.variant_dict.lottery.plural,
        wordSingular: LANG.lib.variant_dict.lottery.singular,
        wordSingularOne: LANG.lib.variant_dict.lottery.singular_one,
    },
    chat: {
        variant: 'chat',
        path: `/${LANG.routes.forum}`,
        wordPlural: LANG.lib.variant_dict.chat.plural,
        wordSingular: LANG.lib.variant_dict.chat.singular,
        wordSingularOne: LANG.lib.variant_dict.chat.singular_one,
    },
    forum: {
        variant: 'forum',
        path: `/${LANG.routes.forum}`,
        wordPlural: LANG.lib.variant_dict.chat.plural,
        wordSingular: LANG.lib.variant_dict.chat.singular,
        wordSingularOne: LANG.lib.variant_dict.chat.singular_one,
    },
}
